import React, { useEffect, useRef } from 'react';

import './SectionTitle.css'

import StyleInterpolator, { mapValueClamped } from '../Helpers/StyleInterpolator';
import { easeInQuart } from '../Helpers/easing';

import { useScrollPosition } from '../Helpers/ScrollHook';

const SectionTitle = React.memo(({ children }) => {
    const scrollY = useScrollPosition();
    const titleRef = useRef()
    const boundsRef = useRef(null)

    useEffect(() => {
        boundsRef.current = titleRef.current.getBoundingClientRect()
    }, [titleRef])


    let ratio;
    if (boundsRef.current) {
        ratio = mapValueClamped(scrollY, [boundsRef.current.top - window.innerHeight*0.9, boundsRef.current.top - window.innerHeight*0.4 ], [0, 1])
    } else {
        ratio = 0;
    }

    return (
        <h1 ref={titleRef} className='section-title' style={slidingInterpolator.interpolateFormatted(ratio)}>{children}</h1>
    );
})


const slidingInterpolator = new StyleInterpolator({
    extrapolate: false,
    easing: (t)=>t,
    properties: {
        transform: {
            format: (t) => `translateY(${t}px)`,
            process: (t) => t.toFixed(0),
            steps: [
                { from: 0, to: 1, initial: 100, final: 0 },
            ],
        },
        opacity: {
            steps: [
                { from: 0.1, to: 1, initial: 0, final: 1 },
            ],
        }
    }
});

export default SectionTitle;
