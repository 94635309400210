import React, { useState, useEffect } from 'react';

import './MacOSDesktop.css';
import IconImage from '../Assets/lasaqa_fill.svg';

const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

const formatTime = (d) => {
    const day = days[d.getDay()];
    let hr = d.getHours();
    const min = (d.getMinutes() < 10 ? '0' : '') + d.getMinutes();
    const ampm = hr >= 12 ? "PM" : "AM";
    hr = hr % 12;
    hr = hr ? hr : 12; // the hour '0' should be '12'
    // const date = d.getDate();
    // const month = months[d.getMonth()];
    return `${hr}:${min} ${ampm}`;
};

const MacOSDesktop = (props) => {
  const [time, setTime] = useState(formatTime(new Date()));

  useEffect(() => {
      const timer = setInterval(() => {
          setTime(formatTime(new Date()));
      }, 120000);

      return () => {
          clearInterval(timer);
      };
  }, []);

  return (
      <div className="mac-os-desktop-desktop">
          <div className="mac-os-desktop-top-bar">
              <div className="mac-os-desktop-logo"></div>
              <div className="mac-os-desktop-info">
                  <img className="mac-os-desktop-lasaqa-icon" ref={props.iconRef} src={IconImage} />
                  <span className="mac-os-desktop-system-icon wifi material-symbols-rounded">wifi</span>
                  <span className="mac-os-desktop-system-icon material-symbols-rounded">search</span>
                  <div className="mac-os-desktop-time">{time}</div>
              </div>
          </div>
          <div className="mac-os-desktop-desktop-content">
              {props.children}
          </div>
      </div>
  );
}

export default MacOSDesktop;