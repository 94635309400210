import React, { useState, useEffect, useRef } from 'react';

const Draggable = ({children, style}) => {
    const nodeRef = useRef();
    const [dragging, setDragging] = useState(false);
    const [boundaries, setBoundaries] = useState({ minX: 0, minY: 0, maxX: 0, maxY: 0 });
    const [translate, setTranslate] = useState({ x: 0, y: 0 });
    const [position, setPosition] = useState({ x: 0, y: 0 });

    const onMouseDown = (e) => {
        if (!nodeRef.current) return;

        const containerWidth = window.innerWidth;
        const containerHeight = window.innerHeight;
        var elemRect = nodeRef.current.getBoundingClientRect();

        const minX = translate.x - elemRect.x;
        const minY = translate.y - elemRect.y + 26;
        const maxX = translate.x + (containerWidth - elemRect.width - elemRect.x);
        const maxY = translate.y + (containerHeight - elemRect.height - elemRect.y);

        setDragging(true);
        setPosition({ x: e.clientX, y: e.clientY });
        setBoundaries({ minX: minX, minY: minY, maxX: maxX, maxY: maxY });
    };

    const onMouseMove = (e) => {
        if (dragging && nodeRef.current) {
            setTranslate((prev) => ({
                x: Math.min(Math.max(prev.x + e.clientX - position.x, boundaries.minX), boundaries.maxX),
                y: Math.min(Math.max(prev.y + e.clientY - position.y, boundaries.minY), boundaries.maxY)
            }));
            setPosition({ x: e.clientX, y: e.clientY });
        }
    };

    const onMouseUp = () => {
        setDragging(false);
    };

    useEffect(() => {
        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mouseup', onMouseUp);

        return () => {
            document.removeEventListener('mousemove', onMouseMove);
            document.removeEventListener('mouseup', onMouseUp);
        };
    }, [dragging, onMouseMove, onMouseUp]);

    return (
        <div ref={nodeRef} onMouseDown={onMouseDown} style={{ ...style,
            zIndex: dragging ? 10 : 5,
            transform: `translate(${translate.x}px, ${translate.y}px)`,
            transition: dragging ? 'none' : 'transform 0.5s',
            pointerEvents: 'all'
        }}>
            {children}
        </div>
    );
};

export default Draggable;
