import React from 'react';

import './ScrollProgress.css'
import { useScrollPosition } from '../Helpers/ScrollHook';
import { mapValueClamped } from '../Helpers/StyleInterpolator';

const getProgressStyle = (ratio) => {
    return {
        width: `${ratio * 100}%`
    };
};

const getCircleStyle = (ratio) => {
    return {
        left: `${ratio * 100}%`
    };
};

const ScrollProgress = React.memo(({ steps, activeKey, ratio }) => {
    return (
        <div className="scroll-progress-container">
            <div className="scroll-progress-bar-wrapper">
                <div className="scroll-progress-bar">
                    <div className="scroll-progress" style={getProgressStyle(ratio)} />
                    {steps.current.map((step) => (
                        <div
                            key={step[0]}
                            className={`scroll-progress-circle ${activeKey > step[0] ? 'done' : ''} ${activeKey === step[0] ? 'current' : ''}`}
                            style={getCircleStyle(step[0])}
                            onClick={() => {
                                window.scrollTo({ top: (window.innerHeight * (1 + step[0]) + 1).toFixed(0), behavior: 'smooth' });
                            }}
                        >
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
})

export default ScrollProgress;
