import React from 'react';

import './Feature.css'

const Feature = React.memo(({className, headline, description, children}) => {
  return (
    <div className={`feature ${className ? className : ''}`}>
      <h3 className="feature-headline">{headline}</h3>
      <p className="feature-description">{description}</p>
      {children}
    </div>
  );
})

export default Feature;