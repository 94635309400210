import React, { useEffect, useRef } from "react";

import './Bio.css';
import Img1 from '../Assets/bio-img1.png';
import Img2 from '../Assets/bio-img2.png';
import Newsletter from '../Components/Newsletter';

import { useScrollPosition } from '../Helpers/ScrollHook';

import StyleInterpolator, { mapValueClamped } from '../Helpers/StyleInterpolator';
import { linear } from '../Helpers/easing';

const Bio = React.memo(() => {
    const scrollY = useScrollPosition();

    const componentRef = useRef()
    const boundsRef = useRef(null)

    useEffect(() => {
        boundsRef.current = componentRef.current.getBoundingClientRect()
    }, [componentRef])


    let ratio;
    if (boundsRef.current) {
        ratio = mapValueClamped(scrollY, [boundsRef.current.top - window.innerHeight, boundsRef.current.top - window.innerHeight*0.01 ], [0, 1])
    } else {
        ratio = 0;
    }

    return (
        <div ref={componentRef} style={backgroundInterpolator.interpolateFormatted(ratio)} className='section bio-container'>
            <div className={"bio-img-container " + (ratio > 0.5 ? "img-revealed" : "")}>
                <img draggable={false} src={Img1} />
                <img draggable={false} src={Img2} />
            </div>
            <div className="bio">
                <div className="bio-description">
                    <p>
                        <b>Hi!</b> I'm Waleed, originally from Saudi, and now deeply rooted in Berlin's tech scene.
                        I've been fiddling with coding since I was 12 and my intrigue hasn't waned one bit.
                        As a backend developer, I spend my days neck-deep in Golang, but my passion also spills
                        over into frontend development and UI design. My passion isn't just about solving problems - I strive
                        to create apps that bring a touch of delight to your everyday life. So, here I am, coding,
                        creating, and excited to see where this journey takes us next.
                    </p>
                    {/* <p>
                            My latest project, this Mac OS app, is a
                            personal answer to the pesky repetitive tasks that bug most of us. It took learning Swift
                            from scratch to make it happen. 
                        </p> */}
                </div>
                {/* <Newsletter /> */}
            </div>
        </div>
    );
});

const backgroundInterpolator = new StyleInterpolator({
    extrapolate: false,
    easing: linear,
    properties: {
        "--background-transition": {
            process: (t) => t.toFixed(3),
            steps: [{ from: 0, to: 1, initial: 0, final: 1 }],
        }
    }
});

export default Bio;