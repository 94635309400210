import React, { useState, useMemo, useEffect } from 'react';
import { Textfit } from 'react-textfit'; // Assuming you're using react-textfit package

const TextReveal = (props) => {
    const [fontSize, setFontSize] = useState(null);

    const { paragraphsWordsChars, charsCount } = useMemo(() => {
        let paragraphsWordsChars = [];
        let charsCount = 0;

        props.children.split('\n').filter(element => element).forEach(function (paragraph) {
            // split the paragraph into chars
            paragraphsWordsChars.push(
                paragraph
                    .trim()
                    .replace(/ +(?= )/g, "")
                    .split("")
                    .filter((w) => w !== "")
                    .map((char) => {
                        charsCount++;
                        return char;
                    })
            );
        });

        return { paragraphsWordsChars, charsCount };
    }, [props.children]); // Recalculate only when props.children changes

    const textFitted = (size) => {
        if (fontSize != null) {
            return;
        }

        setFontSize(size);
    };

    const formatText = useMemo(() => {
        return (revealedCount) => {
            let charsShown = 0;
            let children = [];
            let stopped = false;

            paragraphsWordsChars.forEach((paragraph, i) => {
                if (stopped) {
                    return;
                }

                let chars = [];
                paragraph.forEach((char) => {
                    if (stopped) {
                        return;
                    }

                    charsShown++;
                    let charShown = charsShown <= revealedCount;

                    if (!charShown) {
                        stopped = true;
                        return;
                    };

                    chars.push(char);
                });

                children.push(<span key={`p.${i}`}>{chars.join('')}</span>);

                if (!stopped && i !== paragraphsWordsChars.length - 1) {
                    children.push(<br key={`br.${i}.0`} />);
                    children.push(<br key={`br.${i}.1`} />);
                }
            });

            children.push(<span key={`cursor`} className="cursor">|</span>);

            return children;
        };
    }, [paragraphsWordsChars]);

    const revealedCount = Math.floor(charsCount * props.scrollRatio);

    let style = fontSize ? { fontSize: fontSize } : { opacity: 0 };
    style.height = '100%';

    return (
        <div className={"scroll-text-reveal-content " + (props.highlightedText ? "highlighted" : "")} style={{ ...style, ...props.style }}>
            {
                fontSize ? (
                    props.demoCompleted ? (
                        <>
                            <div className='scroll-text-prev'>{formatText(revealedCount)}</div>
                            <span className='scroll-text-modified'>{props.modifiedText}<span key={`cursor`} className="cursor">|</span></span>
                        </>
                    ) : formatText(revealedCount)
                ) :
                    <Textfit max={1000} min={10} mode="multi" style={{ height: "100%" }} onReady={textFitted}>{formatText(charsCount)}</Textfit>
            }
        </div>
    );
}

export default TextReveal;
