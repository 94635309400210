import React from 'react';

import './DemoExplanations.css'

const DemoExplanations = React.memo(({ steps, activeKey }) => {
    return (
        <div className="demo-explanations-container">
            {steps.current.map((step) => (
                <div key={step[0]} className={"demo-explanation-container " + (step[0] === activeKey ? "shown" : "hidden")}>
                    <h1 className='demo-explanation-title'>{step[1]}</h1>
                    <p className='demo-explanation-description'>{step[2]}</p>
                </div>
            ))}
        </div>
    );
})

export default DemoExplanations;
