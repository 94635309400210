import React, { useState } from 'react';
import './Newsletter.css';

const Newsletter = () => {
    const [email, setEmail] = useState('');
    const [isSubscribed, setIsSubscribed] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();
        setEmail('');
        setIsSubscribed(true);
    };

    return (
        <div className="newsletter">
            {isSubscribed ? (
                <p className="newsletter-success-msg">Thank you for subscribing!</p>
            ) : (
                <form className="newsletter-form" onSubmit={handleSubmit}>
                    <label htmlFor="email-input" className="newsletter-form-label">
                        Join my journey and get updates on my latest projects!
                    </label>
                    <input
                        id="email-input"
                        type="email"
                        className="newsletter-form-input"
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    <button type="submit" className="newsletter-submit-button">
                        Subscribe
                    </button>
                </form>
            )}
        </div>
    );
};

export default Newsletter;
