
import React, { useRef } from 'react';

import { ScrollProvider } from './Helpers/ScrollHook';

import HeroSection from './Sections/Hero';
import Background from './Components/Background';
import ScrollIndicator from './Components/ScrollIndicator';
import HowItWorksSection from './Sections/HowItWorks';
import Logo from './Components/Logo';
import FeaturesSection from './Sections/Features';
import PricingSection from './Sections/Pricing';
import Bio from './Sections/Bio';
import FAQ from './Sections/FAQ';
import Footer from './Sections/Footer';

const tools = [
  [1, "Some Tool 1", "Some tool Description", "Modified! 1"],
  [2, "Some Tool 2", "Some tool Description", "Modified! 2"],
  [3, "Some Tool 3", "Some tool Description", "Modified! 3"],
  [4, "Some Tool 4", "Some tool Description", "Modified! 4"],
  [5, "Some Tool 5", "Some tool Description", "Modified! 5"],
  [6, "Some Tool 6", "Some tool Description", "Modified! 6"],
  [7, "Some Tool 7", "Some tool Description", "Modified! 7"],
  [8, "Some Tool 8", "Some tool Description", "Modified! 8"],
  [9, "Some Tool 9", "Some tool Description", "Modified! 9"],
]

const App = () => {
  const heroIconRef = useRef(null);
  const desktopBarIconRef = useRef(null);

  const toolsRef = useRef(tools)

  return (
    <>
      <ScrollProvider>
        <Background />
        <Logo heroIconRef={heroIconRef} desktopBarIconRef={desktopBarIconRef} />
        <HeroSection iconRef={heroIconRef} toolsRef={toolsRef} />
        <HowItWorksSection iconRef={desktopBarIconRef} toolsRef={toolsRef} />
        <FeaturesSection />
        <PricingSection />
        <FAQ />
        <Bio />
        <Footer />
        <ScrollIndicator />
      </ScrollProvider>
    </>
  );
};

export default App;
