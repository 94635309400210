import React from 'react';
import './Footer.css';

import IconImageFill from '../Assets/icon_white.svg';

const Footer = React.memo(() => {
    return (
        <footer className="footer">
            <div>
                <a href="/" title="">
                    <img draggable={false} width="42px" height="42px" className="lasaqa-logo" src={IconImageFill} alt="" />
                    <b>Lasaqa</b>
                </a>
            </div>

            <div>
                <p>
                    Handcrafted with <span className='heart' role="img" aria-label="love">♥</span> in Berlin<br />© 2023 All rights reserved.
                </p>
            </div>

            <div>
                <a href="#" title=""> Terms &amp; Conditions </a>
                <a href="#" title=""> Privacy Policy </a>
            </div>
        </footer>
    )
});

export default Footer;