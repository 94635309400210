import { linear } from './easing.js'

export default class StyleInterpolator {
    constructor(config) {
        const newConfig = deepClone(config);

        // Set global defaults
        newConfig.from = valueOr(newConfig.from, 0);
        newConfig.to = valueOr(newConfig.to, 1);
        newConfig.easing = valueOr(newConfig.easing, linear);
        newConfig.extrapolate = valueOr(newConfig.extrapolate, true);
        newConfig.allowGaps = valueOr(newConfig.allowGaps, true);

        if (Object.keys(newConfig.properties).length === 0) {
            throw new Error("No properties provided");
        }

        Object.keys(newConfig.properties).forEach(propertyName => {
            const property = newConfig.properties[propertyName];

            // Set property defaults
            property.from = valueOr(property.from, 0);
            property.to = valueOr(property.to, 1);
            property.easing = valueOr(property.easing, linear);
            property.extrapolate = valueOr(property.extrapolate, newConfig.extrapolate);
            property.allowGaps = valueOr(property.allowGaps, newConfig.allowGaps);

            const stepsCount = property.steps.length;
            if (stepsCount === 0) {
                throw new Error(`No steps provided for property ${propertyName}`);
            }

            for (let i = 0; i < stepsCount; i++) {
                const step = property.steps[i];

                // Initial & Final
                if (i === 0 && step.initial === undefined) {
                    throw new Error(`Initial value for the first step is missing for property ${propertyName}`);
                }

                if (i === stepsCount - 1 && step.final === undefined) {
                    throw new Error(`Final value for the last step is missing for property ${propertyName}`);
                }

                // Defaulting 'easing'
                step.easing = valueOr(step.easing, linear);

                // Defaulting 'from' and 'to'
                if (i === 0 && step.from === undefined) step.from = 0;
                if (i === stepsCount - 1 && step.to === undefined) step.to = 1;

                // Defaulting 'from' and 'initial' for steps other than first
                if (i !== 0) {
                    if (step.from === undefined) step.from = property.steps[i - 1].to;
                    if (step.initial === undefined) step.initial = property.steps[i - 1].final;
                }

                // Defaulting 'to' and 'final' for steps other than last
                if (i !== stepsCount - 1) {
                    if (step.to === undefined) step.to = property.steps[i + 1].from;
                    if (step.final === undefined) step.final = property.steps[i + 1].initial;
                }
            }

            // Removing duplicate steps
            property.steps = property.steps.filter((step, i, self) =>
                i === self.findIndex((t) =>
                    t.from === step.from && t.to === step.to && JSON.stringify(t.initial) === JSON.stringify(step.initial) && JSON.stringify(t.final) === JSON.stringify(step.final)
                )
            );

            // Sorting based on 'from' value
            property.steps.sort((a, b) => a.from - b.from);

            // Auto fill gaps in steps
            if (!property.allowGaps) {
                this.fillGaps(property);
            }

            // Overlap and gaps detection
            for (let i = 1; i < property.steps.length; i++) {
                if (property.steps[i].from < property.steps[i - 1].to) {
                    throw new Error(`Overlapping steps detected for property ${propertyName}`);
                }

                if (!property.allowGaps && property.steps[i].from > property.steps[i - 1].to) {
                    throw new Error(`Gap between steps detected for property ${propertyName}`);
                }
            }
        });

        this.config = newConfig;
    }

    fillGaps(property) {
        const filledSteps = [];
        let previousStep = null;
    
        // Check for gap before the first step
        if (property.steps[0].from > 0) {
            const fillerStep = {
                from: 0,
                to: property.steps[0].from,
                initial: property.steps[0].initial,
                final: property.steps[0].initial,
                easing: linear,
            };
            filledSteps.push(fillerStep);
        }
    
        property.steps.forEach((step, index) => {
            if (previousStep && previousStep.to < step.from) {
                const fillerStep = {
                    from: previousStep.to,
                    to: step.from,
                    initial: previousStep.final,
                    final: step.initial,
                    easing: linear,
                };
                filledSteps.push(fillerStep);
            }
    
            filledSteps.push(step);
            previousStep = step;
        });
    
        // Check for gap after the last step
        const lastStep = filledSteps[filledSteps.length - 1];
        if (lastStep.to < 1) {
            const fillerStep = {
                from: lastStep.to,
                to: 1,
                initial: lastStep.final,
                final: lastStep.final,
                easing: linear,
            };
            filledSteps.push(fillerStep);
        }
    
        property.steps = filledSteps;
    }

    interpolate(t) {
        let result = {}

        t = this.config.easing(t);
        Object.keys(this.config.properties).forEach((propertyName) => {
            const property = this.config.properties[propertyName];

            let tP = mapValue(t, [property.from, property.to], [0, 1]);
            tP = property.easing(tP);

            let step;
            if (tP < property.steps[0].from) {
                step = property.steps[0];
            } else if (tP >= property.steps[property.steps.length - 1].to) {
                step = property.steps[property.steps.length - 1];
            } else if (property.steps.length === 1) {
                step = property.steps.find((s) => tP >= s.from && tP <= s.to);
            } else {
                step = property.steps.find((s) => tP >= s.from && tP < s.to);
            }

            if (step == null) {
                if (property.allowGaps) return;
                throw new Error(`Gap between steps detected for property ${propertyName}`);
            }

            // let tS = mapValue(tP, [step.from, step.to], [0, 1]);
            let tS = tP;
            tS = step.easing(tS);

            if (!property.extrapolate) {
                tS = clamp(tS, step.from, step.to)
            }

            let propertyValue;
            if (Array.isArray(step.initial)) {
                propertyValue = []
                step.initial.forEach((v, i) => {
                    propertyValue.push(mapValue(tS, [step.from, step.to], [v, step.final[i]]));
                });
            } else {
                propertyValue = mapValue(tS, [step.from, step.to], [step.initial, step.final]);
            }

            result[propertyName] = propertyValue
        });

        let processed = {}
        Object.keys(result).forEach((propertyName) => {
            const property = this.config.properties[propertyName];
            if (property.process !== undefined && property.process !== null) {
                processed[propertyName] = property.process(result[propertyName], propertyName, result);
                return;
            }
            processed[propertyName] = result[propertyName];
        });

        return processed;
    }

    format(properties) {
        let formatted = {}

        Object.keys(properties).forEach((propertyName) => {
            const format = this.config.properties[propertyName].format;
            if (format === undefined || format === null) {
                formatted[propertyName] = properties[propertyName];
                return;
            }
            formatted[propertyName] = format(properties[propertyName]);
        });

        return formatted;
    }

    interpolateFormatted(t) {
        return this.format(this.interpolate(t));
    }
}

function deepClone(obj) {
    let clone = Array.isArray(obj) ? [] : {};

    Object.keys(obj).forEach(key => {
        if (typeof obj[key] === "object" && obj[key] !== null) {
            clone[key] = deepClone(obj[key]);
        } else {
            clone[key] = obj[key];
        }
    });

    return clone;
}

export function valueOr(value, def) { return value !== undefined && value !== null ? value : def; }
export function clamp(value, min, max) { return Math.max(Math.min(value, max), min); }
export function mapValue(value, fromRange, toRange) {
    const [fromLow, fromHigh] = fromRange;
    const [toLow, toHigh] = toRange;

    const fromRangeSize = fromHigh - fromLow;
    const toRangeSize = toHigh - toLow;

    const scaledValue = (value - fromLow) / fromRangeSize;

    return toLow + (scaledValue * toRangeSize);
}
export function mapValueClamped(value, fromRange, toRange) {
    const [fromLow, fromHigh] = fromRange;
    const [toLow, toHigh] = toRange;

    if (value < fromLow) {
        return toLow;
    }

    if (value > fromHigh) {
        return toHigh;
    }

    const fromRangeSize = fromHigh - fromLow;
    const toRangeSize = toHigh - toLow;

    const scaledValue = (value - fromLow) / fromRangeSize;

    return toLow + (scaledValue * toRangeSize);
}

// let c = {
//     to: 0.2,
//     properties: {
//         color: {
//             format: ([r, g, b]) => `rgb(${r},${g},${b})`,
//             process: ([r, g, b]) => [clamp(r.toFixed(0), 0, 255), clamp(g.toFixed(0), 0, 255), clamp(b.toFixed(0), 0, 255)],
//             from: 0,
//             to: 0.5,
//             allowGaps: true,
//             extrapolate: false,
//             steps: [
//                 { from: 0.1, to: 0.3, initial: [200, 100, 50], final: [100, 200, 20] },
//                 { final: 100 },
//             ],
//             easing: easeIn
//         }
//     }
// }

export function chooseClasses(ratio, ratioList) {
    let result = [];

    ratioList.forEach(item => {
        let startRatio = item.startRatio !== undefined ? item.startRatio : 0;
        let endRatio = item.endRatio !== undefined ? item.endRatio : 1;
        if (startRatio <= ratio && ratio <= endRatio) {
            result.push(item.name);
        }
    });

    return result.join(" ");
}

