import React from 'react';

import './PreviewTool.css'

const ToolPlayButton = ({active, onClick}) => (
  <button 
    className={`material-symbols-rounded preview-tool-play-button`} 
    onClick={onClick}
  >
    {active ? 'pause' : 'play_arrow'}
  </button>
);

const PreviewTool = React.memo(({toolTitle, toolDescription, active, highlightable, highlighted, pulse, toolId, activate}) => {
  const onClick = () => activate(toolId);

  return (
    <div 
      className={"preview-tool-container " + (active ? 'active ' : '') + (highlightable ? 'highlightable ' : '') + (highlighted ? 'highlighted ' : '') + (pulse ? 'pulse ' : '')}
    >
      <div className="preview-tool-tool-header">
        <h1 className="preview-tool-tool-title">{toolTitle}</h1>
        <ToolPlayButton active={active} onClick={onClick} />
      </div>
      <p className="preview-tool-tool-body">{toolDescription}</p>
    </div>
  );
})

export default PreviewTool;