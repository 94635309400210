import React, { createContext, useContext, useEffect, useRef, useState } from "react";

// Create a Scroll context
const ScrollContext = createContext({
  scrollY: 0,
  subscribe: () => {},
});

// Provider component
export function ScrollProvider({ children }) {
  const [scrollY, setScrollY] = useState(getScrollPosition());
  const subscriptions = useRef(new Set());

  const subscribe = (fn) => {
    subscriptions.current.add(fn);
    return () => subscriptions.current.delete(fn);
  };

  // Setup a single scroll listener when component mounts
  // and clean it up on unmount
  useEffect(() => {
    const handleScroll = () => {
      const position = getScrollPosition();
      setScrollY(position);
      subscriptions.current.forEach((fn) => fn(position));
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <ScrollContext.Provider value={{ scrollY, subscribe }}>
      {children}
    </ScrollContext.Provider>
  );
}

export function getScrollPosition() {
  return (
    window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0
  );
}

// Custom hook to use Scroll position
export function useScrollPosition() {
  const context = useContext(ScrollContext);

  if (!context) {
    throw new Error('useScrollPosition must be used within a ScrollProvider');
  }

  return context.scrollY;
}

// Custom hook to subscribe to Scroll position changes
export function useScrollSubscription() {
  const context = useContext(ScrollContext);

  if (!context) {
    throw new Error('useScrollSubscription must be used within a ScrollProvider');
  }

  return context.subscribe;
}
