import { useState, useCallback } from 'react';

const useActiveTool = (initialTool) => {
    const [activeTool, setActiveTool] = useState(initialTool);

    const activateTool = useCallback((id) => {
        if (activeTool === id) {
            setActiveTool(0);
        } else {
            setActiveTool(id);
        }
    }, [activeTool]);

    return [activeTool, activateTool];
};

export default useActiveTool;