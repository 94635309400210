import React, { useRef, useEffect } from 'react';

import './Pricing.css'

import { useScrollPosition } from '../Helpers/ScrollHook';

import StyleInterpolator, { mapValueClamped } from '../Helpers/StyleInterpolator';
import { linear } from '../Helpers/easing';

import SectionTitle from '../Components/SectionTitle';

const PricingSection = () => {
    const scrollY = useScrollPosition();

    const componentRef = useRef()
    const boundsRef = useRef(null)

    useEffect(() => {
        boundsRef.current = componentRef.current.getBoundingClientRect()
    }, [componentRef])


    let ratio;
    if (boundsRef.current) {
        ratio = mapValueClamped(scrollY, [boundsRef.current.top - window.innerHeight, boundsRef.current.top - window.innerHeight * 0.01], [0, 1])
    } else {
        ratio = 0;
    }

    return (
        <div ref={componentRef} style={backgroundInterpolator.interpolateFormatted(ratio)} className="section pricing" id="pricing">
            <SectionTitle>Simple Pricing</SectionTitle>
            <div className="pricing-container">
                <div className="pricing-content">
                    <div className="pricing-details">
                        <div className="pricing-price">
                            <h3><span>$11</span> <strong>$6</strong><sub>per year</sub></h3>
                            <h5>Save <strong>45%</strong> for a limited time</h5>
                        </div>
                        <p>1 device per licence.</p>
                        <ul className="pricing-checklist">
                            <li>14 day free trial</li>
                            <li>All the bells and whistles</li>
                            <li>All updates for a year</li>
                        </ul>
                    </div>

                    <div className="pricing-buttons">
                        <a href="#" className="pricing-buy-button">Buy now</a>
                        <a href="#" className="pricing-trial-button">Start free trial</a>
                    </div>
                </div>
            </div>
        </div>
    );
};

const backgroundInterpolator = new StyleInterpolator({
    extrapolate: false,
    easing: linear,
    properties: {
        "--background-transition": {
            process: (t) => t.toFixed(3),
            steps: [{ from: 0, to: 1, initial: 0, final: 1 }],
        }
    }
});

export default PricingSection;