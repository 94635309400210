import React from 'react';

import './Hero.css';

import TiltingPreview from '../Components/TiltingPreview';
import CTAButton from '../Components/CTAButton';
import IconImage from '../Assets/icon.png';
import PreviewTool from '../Components/PreviewTool';

import { useScrollPosition } from '../Helpers/ScrollHook';
import useActiveTool from '../Helpers/ActiveToolHook';
import { CopyPasteKeySequence } from '../Components/KeysSequence';

import StyleInterpolator from '../Helpers/StyleInterpolator';
import { easeInOutSine } from '../Helpers/easing';
import { chooseClasses, mapValueClamped } from '../Helpers/StyleInterpolator';

const HeroSection = (props) => {
  const scrollY = useScrollPosition();
  const [activeTool, activateTool] = useActiveTool(1);

  const ratio = mapValueClamped(scrollY, [0, window.innerHeight], [0, 1]);
  const slidingStyle = slidingHeroInterpolator.interpolateFormatted(ratio)

  return (
    <div
      className={"hero-container" + chooseClasses(ratio, [{ name: " no-pointer-events", startRatio: 0.25 }])}
      style={slidingStyle}>
      <div className="hero-column">
        <TiltingPreview>
          {props.toolsRef.current.map((tool) => (
            <PreviewTool key={tool[0]} toolId={tool[0]} toolTitle={tool[1]} toolDescription={tool[2]} active={activeTool===tool[0]} activate={activateTool} />
          ))}
        </TiltingPreview>
      </div>
      <div className="hero-column">
        <img draggable={false} ref={props.iconRef} tabIndex="0" className="hero-icon lasaqa-logo-animation" src={IconImage} />
        <div>
          <h1 className="hero-headline">
            Do a Lot <span className="hero-highlighted-word">More</span>
          </h1>
          <h1 className="hero-headline">
            While You
          </h1>
          <CopyPasteKeySequence />
        </div>
        <p className="hero-subline">With <b>Lasaqa</b>, you can modify your data as you copy it so you never have to waste time or lose focus by carrying data to another app.</p>
        <div>
          <CTAButton>Get it now</CTAButton>
        </div>
      </div>
    </div>
  );
};

const slidingHeroInterpolator = new StyleInterpolator({
  extrapolate: false,
  easing: easeInOutSine,
  properties: {
    transform: {
      format: (t) => `translateY(-${t}px)`,
      process: (t) => t.toFixed(0),
      steps: [
        { from: 0, to: 1, initial: 0, final: 250 },
      ],
    }
  }
});

export default HeroSection;