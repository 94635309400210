import React, { useEffect, useState, useRef } from 'react';

import IconImageFill from '../Assets/icon_white.svg';
import IconImage from '../Assets/icon.png';

import { mapValueClamped } from '../Helpers/StyleInterpolator';

import { useScrollPosition } from '../Helpers/ScrollHook';

import './Logo.css';
import { linear } from '../Helpers/easing';

const Logo = (props) => {
  const scrollY = useScrollPosition();
  const ratio = Math.round(mapValueClamped(scrollY, [0, window.innerHeight * 1.1], [0, 200]));

  const { heroIconRef, desktopBarIconRef } = props;

  const [render, setRender] = useState(false);
  const animationSteps = useRef([]); // store the animation steps here

  useEffect(() => {
    if (heroIconRef.current) {
      setRender(true);
    }
  }, [heroIconRef]);

  useEffect(() => {
    if (render) {
      setTimeout(() => {
        heroIconRef.current.style.opacity = 0;
        desktopBarIconRef.current.style.opacity = 0;
      }, 60);
    }
  }, [render])

  // Pre-calculate the animation steps
  useEffect(() => {
    const heroIconBounds = bounds(heroIconRef.current)
    let desktopBarIconBounds = bounds(desktopBarIconRef.current)
    desktopBarIconBounds.top -= window.innerHeight - 10;
    desktopBarIconBounds.left -= 4;

    for (let i = 0; i <= 200; i++) {
      const ratio = i / 200;

      const startPos = heroIconRef.current ? heroIconBounds : { left: 0, top: 0 }

      animationSteps.current[i] = {
        "--logo-offset-left": `${startPos.left}px`,
        "--logo-offset-top": `${startPos.top}px`,
        transform: transformBetweenRefs(heroIconRef, desktopBarIconRef, heroIconBounds, desktopBarIconBounds, ratio, linear),
        "--icon-transition": mapValueClamped(ratio, [0.9, 0.99], [0, 1]),
      };
    }
  }, [render]);

  const style = animationSteps.current[ratio];

  return (
    <div style={{ position: 'fixed', zIndex: 10, height: '100vh', width: '100vw', pointerEvents: 'none', top: 0 }}>
      {render ?
        (<div className={"logo-icon-container " + (scrollY >= window.innerHeight * 2 ? 'sticky ' : '')} style={style}>
          <div tabIndex="2" className='lasaqa-logo-animation' onClick={() => {
            if (scrollY >= window.innerHeight * 2) {
              window.scrollTo({ top: 0 })
            }
          }}>
            <img draggable={false} className="logo-icon" src={IconImageFill} />
            <img draggable={false} className="logo-icon" src={IconImage} />
          </div>
        </div>) :
        (<div className="logo-icon-container"></div>)}
    </div>
  );
}

function transformBetweenRefs(ref1, ref2, rect1, rect2, ratio, easing) {

  if (ref1 == null || ref1.current == null || ref2 == null || ref2.current == null) {
    return 'translate(0,0)';
  }

  ratio = easing(ratio)

  // Scale differences by ratio
  const scale = 1 + (((rect2.height + 4) / rect1.height) - 1) * ratio;

  const pointA = { x: rect1.left - rect1.width / 2, y: rect1.top - rect1.height / 2 }
  const pointB = { x: rect2.left - rect2.width / 2, y: rect2.top - rect2.height / 2 }
  const pointCont = { x: (pointA.x - pointB.x) / 2, y: Math.min(rect1.top * 12, 800) }

  const point = interpolateBezierCurve(pointA, pointB, pointCont, ratio);

  // Calculate difference in positions
  const dx = point.x - rect1.left - (rect1.width / 2) + (rect2.width);
  const dy = point.y - rect1.top - (rect1.height / 2) + (rect2.height);

  const moveX = dx * ratio;
  const moveY = dy * ratio;

  // Create CSS transform property
  // Make sure to scale first, then translate
  return `translate(${moveX.toFixed(0)}px, ${moveY.toFixed(0)}px) scale(${scale.toFixed(5)})`;
}

function bounds(element) {
  var xPosition = 0;
  var yPosition = 0;

  function calculate(element) {
    xPosition += element.offsetLeft - element.scrollLeft + element.clientLeft;
    yPosition += element.offsetTop - element.scrollTop + element.clientTop;

    if (element.offsetParent) {
      calculate(element.offsetParent);
    }
  }

  calculate(element);

  return { left: xPosition, top: yPosition, width: element.offsetWidth, height: element.offsetHeight };
}

function interpolateBezierCurve(pointA, pointB, controlPoint, ratio) {
  // Calculate points on lines AB and BC
  let pointAB = {
    x: (1 - ratio) * pointA.x + ratio * controlPoint.x,
    y: (1 - ratio) * pointA.y + ratio * controlPoint.y
  };

  let pointBC = {
    x: (1 - ratio) * controlPoint.x + ratio * pointB.x,
    y: (1 - ratio) * controlPoint.y + ratio * pointB.y
  };

  // Calculate point on line AB-BC
  let pointFinal = {
    x: (1 - ratio) * pointAB.x + ratio * pointBC.x,
    y: (1 - ratio) * pointAB.y + ratio * pointBC.y
  };

  return pointFinal;
}

export default Logo;