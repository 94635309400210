import React from 'react';

import './Background.css'

import { useScrollPosition } from '../Helpers/ScrollHook';

import StyleInterpolator, { mapValueClamped } from '../Helpers/StyleInterpolator';
import { easeInQuart, easeOutQuart } from '../Helpers/easing';


const Background = React.memo(() => {
    const scrollY = useScrollPosition();
    const ratio = mapValueClamped(scrollY, [0, window.innerHeight], [0, 1]);

    return (
        <div style={backgroundInterpolator.interpolateFormatted(ratio)} className="background">
            <div className="background-blob" />
            <div className="background-blob" />
        </div>
    );
})

const backgroundInterpolator = new StyleInterpolator({
    extrapolate: false,
    easing: easeOutQuart,
    properties: {
        "--background-transition": {
            process: (t) => t.toFixed(3),
            steps: [{ from: 0, to: 1, initial: 1, final: 0 }],
        }
    }
});

export default Background;