import React from 'react';

import './CTAButton.css'

const CTAButton = React.memo((props) => {
  return (
      <a className="cta-button-button" href="#pricing" {...props}>
          {props.children}
      </a>
  );
})

export default CTAButton;