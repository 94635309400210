import React, { useState, useEffect } from 'react';

import './Key.css'
import './KeysSequence.css'

export const CopyPasteKeySequence = (props) => {
  return (
    <div className='copy-paste-key-sequence' {...props}>
      <div className="key-key copy-paste-key-sequence-cmdKey"><i>⌘</i></div>
      <div className="key-key copy-paste-key-sequence-cKey"><i>C</i></div>
      <div className="key-key copy-paste-key-sequence-vKey"><i>V</i></div>
    </div>
  );
};

export const OptSpaceKeySequence = (props) => {
  return (
    <div className='demo-key-sequence opt-space-key-sequence' {...props}>
      <div className="key-key demo-key-sequence-modifier"><i>⌥</i></div>
      <div className="key-key demo-key-sequence-key space-key"><i>SPACE</i></div>
    </div>
  );
};

export const CopyKeySequence = (props) => {
  return (
    <div className='demo-key-sequence copy-key-sequence' {...props}>
      <div className="key-key demo-key-sequence-modifier"><i>⌘</i></div>
      <div className="key-key demo-key-sequence-key"><i>C</i></div>
    </div>
  );
};

export const PasteKeySequence = (props) => {
  return (
    <div className='demo-key-sequence paste-key-sequence' {...props}>
      <div className="key-key demo-key-sequence-modifier"><i>⌘</i></div>
      <div className="key-key demo-key-sequence-key"><i>V</i></div>
    </div>
  );
};

// const totalTime = 3200;
// const transition = 200;

// let currTime = 0;
// let cmdRel = [0];
// let cRel  = [0];
// let vRel  = [0];
// let cmdPre = [];
// let cPre  = [];
// let vPre  = [];

// setup();

// function setup() {
//   press('cmd');
//   sleep(200);
//   press('c');
//   sleep(700);
//   release('cmd');
//   release('c');
//   sleep(800);
//   press('cmd');
//   sleep(200);
//   press('v');
//   sleep(700);
//   release('cmd');
//   release('v');
  
//   console.log(cmdRel, cmdPre);
//   console.log(cRel, cPre);
//   console.log(vRel, vPre);
// }

// function press(key) {
//   let rel;
//   let pre;
//   switch(key) {
//     case 'cmd':
//       rel = cmdRel;
//       pre = cmdPre;
//       break;
//     case 'c':
//       rel = cRel;
//       pre = cPre;
//       break;
//     case 'v':
//       rel = vRel;
//       pre = vPre;
//       break;
//   }
//   rel.push((currTime / totalTime).toFixed(2) * 100 + "%");
//   pre.push(((currTime+transition) / totalTime).toFixed(2) * 100 + "%");
// }

// function release(key) {
//   let rel;
//   let pre;
//   switch(key) {
//     case 'cmd':
//       rel = cmdRel;
//       pre = cmdPre;
//       break;
//     case 'c':
//       rel = cRel;
//       pre = cPre;
//       break;
//     case 'v':
//       rel = vRel;
//       pre = vPre;
//       break;
//   }
//   pre.push((currTime / totalTime).toFixed(2) * 100 + "%");
//   rel.push(((currTime+transition) / totalTime).toFixed(2) * 100 + "%");
// }

// function sleep(ms) {
//   currTime += ms;
// }