import React, { useState, useRef, useEffect } from 'react';

import './FAQ.css'
import SectionTitle from '../Components/SectionTitle';

const questions = [
    { title: "What does Lasaqa mean?", content: "The name \"Lasaqa\" is derived from the Arabic word for bandaid. It symbolizes how the app acts as a solution for your copy-pasting woes, enhancing your productivity by providing powerful text transformation tools. It also sounds similar to the Arabic verb for \"Paste\", \"Lasaq\"." },
    { title: "Is Lasaqa compatible with my operating system?", content: "Lasaqa is available for macOS, providing seamless integration and powerful text transformations specifically designed for the macOS environment." },
    { title: "Is my data secure with Lasaqa?", content: "Yes, Lasaqa prioritizes data security. All text modifications occur locally on your device, ensuring your sensitive information remains private and never leaves your machine." },
    { title: "Why does Lasaqa require Accessibility permissions?", content: "Lasaqa requests Accessibility permissions to enable advanced text transformation capabilities across various apps. This ensures that Lasaqa can seamlessly interact with other applications and perform its tasks with enhanced efficiency." },
    { title: "How can I get support if I have an issue or question?", content: <>I'm here to help! You can reach out to me via email at <a href="mailto:support@lasaqa.app">support@lasaqa.app</a>, and I'll promptly assist you with any inquiries or concerns you may have.</> },
]

const FAQ = () => {
    const [activeIndex, setActiveIndex] = useState(null);
    const [maxHeight, setMaxHeight] = useState(0);

    const contentRefs = useRef([]);

    useEffect(() => {
        const heights = contentRefs.current.map(ref => ref.scrollHeight);
        const max = Math.max(...heights);
        setMaxHeight(max);
    }, []);

    const onTitleClick = (index) => {
        if (index === activeIndex) {
            setActiveIndex(-1);
        } else {
            setActiveIndex(index);
        }
    };

    const renderedQuestions = questions.map((question, index) => {
        const active = index === activeIndex ? 'active' : '';

        return (
            <React.Fragment key={index}>
                <div className={`question-container ${active}`}>
                    <div className='title' onClick={() => onTitleClick(index)}>
                        <span className="toggle-expand-arrow material-symbols-rounded">expand_more</span>
                        {question.title}
                    </div>
                    <div ref={el => contentRefs.current[index] = el} className='content'>
                        <p>{question.content}</p>
                    </div>
                </div>
            </React.Fragment>
        );
    });

    return (
        <div className='section faq' style={{ '--max-height': `${maxHeight}px` }}>
            <SectionTitle>Questions</SectionTitle>
            <div className='faq-content'>
                <div className="accordion">
                    {renderedQuestions}
                </div>
            </div>
        </div>
    );
};

export default FAQ;