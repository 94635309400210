import React from 'react';

import './MacOSWindow.css';

const MacOSWindow = React.memo((props) => {
  return (
    <div className="mac-os-window-window" style={props.style}>
      <div className="mac-os-window-window-header">
        <div className="mac-os-window-buttons-container">
          <button className="mac-os-window-button" />
          <button className="mac-os-window-button" />
          <button className="mac-os-window-button" />
        </div>
        <h1 className="mac-os-window-window-title">{props.windowTitle}</h1>
        <div className="mac-os-window-header-spacer"> </div>
      </div>
      <div className="mac-os-window-content-container">
        {props.children}
      </div>
    </div>
  );
})

export default MacOSWindow;