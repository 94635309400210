
import React from "react";
import Tilt from 'react-parallax-tilt';
import Preview from "./Preview";


class TiltingPreview extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div style={{ ...this.props.style, display: "flex", transition: 'transform 0.1s ease-out'}}>
                <Tilt glareEnable={true} glareMaxOpacity={0.2} glarePosition="all" glareColor="#fff8" glareBorderRadius="15px" tiltMaxAngleX={5} tiltMaxAngleY={5}>
                    <Preview>
                        {this.props.children}
                    </Preview>
                </Tilt>
            </div>
        );
    }
}

export default TiltingPreview;