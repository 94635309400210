import React from 'react';

import './ScrollIndicator.css'

import { useScrollPosition } from '../Helpers/ScrollHook';

const ScrollIndicator = () => {
  const scrollY = useScrollPosition();
  const opacity = scrollY <= window.innerHeight * 2 ? 1 : 0;

  return (
    <div className={"scroll-indicator-container " + (scrollY > window.innerHeight ? "inactive" : "")}>
      <i
        className="material-symbols-rounded scroll-indicator-icon"
        style={{ opacity: opacity }}
        onClick={() => {
          window.scrollTo({ top: window.innerHeight * 1.1, behavior: 'smooth' });
        }}
      >keyboard_arrow_down</i>
    </div>
  );
}

export default ScrollIndicator;
