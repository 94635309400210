import React, { useEffect, useRef } from 'react';

import './Demo.css';

import { useScrollPosition } from '../Helpers/ScrollHook';
import useActiveTool from '../Helpers/ActiveToolHook';

import MacOSDesktop from '../Components/MacOSDesktop';
import Draggable from '../Components/Draggable';
import TextReveal from '../Components/TextReveal';
import MacOSWindow from '../Components/MacOSWindow';
import ScrollProgress from '../Components/ScrollProgress';

import StyleInterpolator, { chooseClasses, mapValueClamped } from '../Helpers/StyleInterpolator';
import { easeInQuart } from '../Helpers/easing';
import Preview from '../Components/Preview';
import PreviewTool from '../Components/PreviewTool';
import { OptSpaceKeySequence, CopyKeySequence, PasteKeySequence } from '../Components/KeysSequence';

const windowInterpolator = new StyleInterpolator({
    extrapolate: false,
    easing: easeInQuart,
    extrapolate: false,
    properties: {
        scale: {
            format: (s) => `${s}%`,
            process: (s) => (s * 100).toFixed(2),
            steps: [
                { from: 0, to: 1, initial: 2.5, final: 1 },
            ],
        },
        translate: {
            format: (y) => `0 ${y}%`,
            process: (y) => (y * 100).toFixed(2),
            steps: [
                { from: 0, to: 1, initial: 1.2, final: 0 },
            ],
        },
        opacity: {
            steps: [
                { from: 0.15, to: 0.75, initial: 0, final: 1 },
            ],
        },
    }
})

const DemoSection = (props) => {
    const scrollY = useScrollPosition();
    const [activeTool, activateTool] = useActiveTool(0);
    const previewScrollRef = useRef(null);

    const ratio = mapValueClamped(scrollY, [window.innerHeight, window.innerHeight * 2], [0, 1]);
    const windowRatio = mapValueClamped(scrollY, [-window.innerHeight * 0.25, window.innerHeight * 1.1], [0, 1]);
    const textRatio = mapValueClamped(scrollY, [window.innerHeight * 0.5, window.innerHeight * 1.1], [0, 1]);

    let stage;
    if (ratio < 0.2) { } // nothing
    else if (ratio < 0.3) { stage = 1 } // opt+space
    else if (ratio < 0.4) { stage = 1.5 } // lasaqa preview
    else if (ratio < 0.55) { stage = 1.75 } // tools highlight
    else if (ratio < 0.6) { stage = 2 } // tool selected
    else if (ratio < 0.65) { stage = 3 } // lasaqa close
    else if (ratio < 0.7) { stage = 3.25 } // text highlighted
    else if (ratio < 0.8) { stage = 3.5 } // cmd+c
    else if (ratio < 0.9) { stage = 4 } // cmd+v
    else if (ratio <= 1) { stage = 4.5 } // modify

    let highlightedTool;
    if (ratio >= 0.4 && ratio <= 0.6) {
        const highlightRatio = mapValueClamped(ratio, [0.4, 0.55], [0, 1]);
        if (highlightRatio < 0.33) { highlightedTool = 1 }
        else if (highlightRatio < 0.66) { highlightedTool = 2 }
        else if (highlightRatio <= 1) { highlightedTool = 3 }
    }

    useEffect(() => {
        // reset active tool if preview is closed
        if ((!stage || stage < 2) && activeTool > 0) {
            activateTool(0)
        }

        // select tool if not selected already
        if (stage >= 2 && activeTool === 0) {
            activateTool(3)
        }
    }, [stage])

    return (
        <div style={props.style}>
            <div className={"demo-shortcuts-container " + (stage == 1 || stage == 3.5 || stage == 4 ? "shown" : "")}>
                {stage == 1 ? <OptSpaceKeySequence /> : null}
                {stage == 3.5 ? <CopyKeySequence /> : null}
                {stage == 4 ? <PasteKeySequence /> : null}
            </div>
            <MacOSDesktop
                iconRef={props.iconRef}>
                <Draggable>
                    <MacOSWindow
                        windowTitle="lasaqa copy final_3.md"
                        style={windowInterpolator.interpolateFormatted(windowRatio)}>
                        <TextReveal
                            highlightedText={stage >= 3.25 && stage <= 4}
                            scrollRatio={easeInQuart(textRatio)}
                            demoCompleted={!(!stage || stage < 4.5 || !activeTool || activeTool === 0)}
                            modifiedText={activeTool > 0 ? props.toolsRef.current[activeTool-1][3] : ""}>
                            {demoCopy}
                        </TextReveal>
                    </MacOSWindow>
                </Draggable>
                {stage >= 1.5 && stage < 3 ?
                    <Draggable>
                        <Preview scrollRef={previewScrollRef}>
                            {props.toolsRef.current.map((tool) => (
                                <PreviewTool
                                key={tool[0]}
                                toolId={tool[0]}
                                toolTitle={tool[1]}
                                toolDescription={tool[2]}
                                active={activeTool === tool[0]}
                                highlighted={ activeTool == 0 && highlightedTool === tool[0]}
                                pulse={activeTool === 0}
                                highlightable={true}
                                activate={(id)=>{
                                    window.scrollTo({ top: (window.innerHeight * (1 + 0.55) + 1).toFixed(0) });
                                    activateTool(id)
                                }} />
                            ))}
                        </Preview>
                    </Draggable> : null}
            </MacOSDesktop>
            <ScrollProgress
                ratio={ratio}
                steps={props.steps}
                activeKey={props.activeKey}
            />
        </div>
    );
};

const demoCopy = `Copy-pasting.
A simple action we do a bazillion times a day, but what if this familiar action did more than just move data around?
Lasaqa reinvents it with a fresh, friendly twist, creating a reality where copy-paste seamlessly transforms your data as well.
Experience this and make your clipboard smarter and your tasks easier.`

// {/* <Draggable>
//     <Preview>
//         <PreviewTool title="Some tool" description="you can transform your data as you copy it so you never have to waste time or lose focus by carrying data to another app" />
//         <PreviewTool title="Some other tool" description="you can transform your data as you copy it so you never have to waste time or lose focus by carrying data to another app" />
//         <PreviewTool title="Some other tool" description="you can transform your data as you copy it so you never have to waste time or lose focus by carrying data to another app" />
//         <PreviewTool title="Some other tool" description="you can transform your data as you copy it so you never have to waste time or lose focus by carrying data to another app" />
//         <PreviewTool title="Some other tool" description="you can transform your data as you copy it so you never have to waste time or lose focus by carrying data to another app" />
//         <PreviewTool title="Some other tool" description="you can transform your data as you copy it so you never have to waste time or lose focus by carrying data to another app" />
//         <PreviewTool title="Some other tool" description="you can transform your data as you copy it so you never have to waste time or lose focus by carrying data to another app" />
//         <PreviewTool title="Some other tool" description="you can transform your data as you copy it so you never have to waste time or lose focus by carrying data to another app" />
//     </Preview>
// </Draggable> */}

export default DemoSection;