
import React from 'react';

import './Preview.css';
import IconImage from '../Assets/lasaqa_fill.svg';

const Preview = React.memo((props) => {
    return (
        <div className="preview-window-preview-panel">
            <div className="preview-window-preview-header">
                <img draggable={false} tabIndex="1" className="preview-window-preview-logo lasaqa-logo-animation" src={IconImage} />
                <span className="preview-window-preview-search">
                    <input className="preview-window-preview-search-input" placeholder="Search" />
                </span>
            </div>
            <div ref={props.scrollRef} className="preview-window-preview-content-container">
                <div className="preview-window-preview-content">
                    {props.children}
                </div>
            </div>
        </div>
    );
})

export default Preview;