import React, { useRef, useMemo } from 'react';

import './HowItWorks.css';

import { useScrollPosition } from '../Helpers/ScrollHook';

import StyleInterpolator, { mapValueClamped } from '../Helpers/StyleInterpolator';
import { easeInQuart } from '../Helpers/easing';

import DemoSection from './Demo';
import DemoExplanations from '../Components/DemoExplanations';
import SectionTitle from '../Components/SectionTitle';

const HowItWorksSection = (props) => {
    const scrollY = useScrollPosition();
    const ratio = mapValueClamped(scrollY, [-window.innerHeight * 0.25, window.innerHeight * 1.1], [0, 1]);
    const demoRatio = mapValueClamped(scrollY, [window.innerHeight, window.innerHeight * 2], [0, 1]);

    const steps = useRef([
        [0.2, "Open Lasaqa", "Using ⌥+Space, you will be able to interact with Lasaqa"],
        [0.4, "Select a transformation", "By clicking on the ⏵ button, you will activate the tool"],
        [0.6, "Copy your text", "Select your text and copy it"],
        [0.8, "Paste anywhere", "Paste with ⌘+V anywhere to see the magic happen"],
    ])

    const activeStep = steps.current.find((step, i) => demoRatio >= step[0] && (i === steps.current.length - 1 || demoRatio < steps.current[i + 1][0]));
    const activeKey = demoRatio >= 1 ? 1 : (activeStep ? activeStep[0] : -1)

    return (
        <div className='how-it-works-container'>
            <div style={backgroundInterpolator.interpolateFormatted(ratio)} className='section how-it-works'>
                <SectionTitle>How it works</SectionTitle>
                <div className='how-it-works-screen' style={demoInterpolator.interpolateFormatted(ratio)}>
                    <DemoSection iconRef={props.iconRef} steps={steps} toolsRef={props.toolsRef} activeKey={activeKey} />
                </div>
                <DemoExplanations steps={steps} activeKey={activeKey} />
            </div>
        </div>
    );
};

const backgroundInterpolator = new StyleInterpolator({
    extrapolate: false,
    easing: easeInQuart,
    properties: {
        "--background-transition": {
            process: (t) => t.toFixed(3),
            steps: [{ from: 0, to: 1, initial: 0, final: 1 }],
        }
    }
});

const demoInterpolator = new StyleInterpolator({
    extrapolate: false,
    easing: easeInQuart,
    extrapolate: false,
    properties: {
        transform: {
            format: ([s, y, z, r]) => `perspective(200vh) scale(${s}%) translate3d(0,${y}%, ${z}px) rotateX(${r}deg)`,
            process: ([s, y, z, r]) => [(s * 100).toFixed(2), (y * 100).toFixed(2), z.toFixed(0), r.toFixed(2)],
            steps: [
                { from: 0.1, to: 1, initial: [0.95, .1, -600, 30], final: [1, 0, 0, 0] },
            ],
        },
        opacity: {
            steps: [
                { from: 0.25, to: 0.9, initial: 0, final: 1 },
            ],
        }
    }
})

export default HowItWorksSection;