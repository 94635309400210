import React, { useRef, useMemo } from 'react';

import './Features.css';

import { useScrollPosition } from '../Helpers/ScrollHook';

import StyleInterpolator, { mapValueClamped } from '../Helpers/StyleInterpolator';
import { easeInQuart } from '../Helpers/easing';

import Feature from '../Components/Feature';
import SectionTitle from '../Components/SectionTitle';

const FeaturesSection = (props) => {
    const scrollY = useScrollPosition();
    const ratio = mapValueClamped(scrollY, [-window.innerHeight * 0.25, window.innerHeight * 1.1], [0, 1]);

    return (
        <div className='section features'>
            <SectionTitle>Awesome Features</SectionTitle>
            <div className='features-container'>
                <Feature
                    className="grid-area-a spanning"
                    headline="Powerful Modifiers"
                    description="Manipulate your clipboard uniquely with our Advanced Toolkit."
                >
                </Feature>
                <Feature
                    className="grid-area-b constrained"
                    headline="Offline and Private"
                    description="Ensure offline productivity and privacy with unaffected functionality."
                >
                </Feature>
                <Feature
                    className="grid-area-c constrained"
                    headline="Copy-Paste Anywhere"
                    description="Universal Compatibility integrates across macOS applications."
                >
                </Feature>
                <Feature
                    className="grid-area-d spanning"
                    headline="Make it your own"
                    description="Customize and automate workflows with our Scriptable Interface."
                >
                </Feature>
                <Feature
                    className="grid-area-e spanning"
                    headline="Stack and Build"
                    description="Craft custom solutions with our tool builder feature."
                >
                </Feature>
                <Feature
                    className="grid-area-f constrained"
                    headline="Limitless Generators"
                    description="Generate text patterns swiftly with our Generator Tools."
                >
                </Feature>
            </div>
        </div>
    );
};

export default FeaturesSection;